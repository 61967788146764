/* eslint-disable @typescript-eslint/no-unnecessary-condition */
import { graphql } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { SanityImageType } from '../../graphql-fragments/SanityImage';
import { uniqBy } from '../../utils/nodash';
import { getPortableText, getPortableTextAsString } from '../../utils/sanity';
import { clsx, replaceNewLinesWithBr, slugify } from '../../utils/utils';
import Form, { FormField, FormFieldWithId } from './Form';
import * as styles from './Hero.module.scss';
import Image from './Image';
import ModuleLayout from './ModuleLayout';
import TermsAndConditionsModal from './TermsAndConditionsModal';
import TextLink from './TextLink';

export const query = graphql`
  fragment Hero on SanityGtgvBookLandingPageHero {
    title
    text
    image {
      ...SanityImage
    }
    buttonText
    destinationUrlOnSubmit
    conditionalDestinationsUrl {
      parameterName
      parameterValue
      destinationUrl
    }
    onSubmitKeapTagIds
  }
`;

export type HeroProps = {
  title: string;
  text?: string;
  image: SanityImageType;
  buttonText: string;
  destinationUrlOnSubmit: string;
  conditionalDestinationsUrl?: Array<{
    parameterName: string;
    parameterValue: string;
    destinationUrl: string;
  }>;
  onSubmitKeapTagIds: Array<string>;
  className?: string;
};

const Hero = ({
  title,
  text,
  image,
  buttonText,
  destinationUrlOnSubmit,
  conditionalDestinationsUrl,
  onSubmitKeapTagIds,
  className,
}: HeroProps): React.ReactElement => {
  const [isModalOpen, setIsModalOpen] = useState(false);

  const rowFields: Array<FormField> = [
    {
      crmFieldId: 'name',
      fieldType: 'textSingleLine',
      isFieldRequired: true,
      title: 'Name',
    },
    {
      crmFieldId: 'phoneNumber',
      fieldType: 'textSingleLine',
      isFieldRequired: true,
      title: 'Phone Number',
      withPhoneNumberValidation: true,
    },
  ];

  const fields = [
    {
      crmFieldId: 'email',
      fieldType: 'textSingleLine',
      isFieldRequired: true,
      title: 'Email',
      withEmailValidation: true,
    },
    {
      crmFieldId: 'acceptTheTerms',
      fieldType: 'singleCheckbox',
      isFieldRequired: true,
      textNode: (
        <p>
          I agree to the{' '}
          <TextLink
            className={styles.termsLink}
            styleOnly
            withExternalIcon={false}
            onClick={() => {
              setIsModalOpen(true);
            }}
          >
            terms and conditions
          </TextLink>{' '}
        </p>
      ),
      _rawText: getPortableText(['I agree to the terms and conditions']),
    },
    {
      crmFieldId: 'joinTheHookPointNewsletter',
      fieldType: 'singleCheckbox',
      isFieldRequired: false,
      _rawText: getPortableText(['Join The Going Viral Newsletter!']),
    },
  ] as Array<FormField>;

  const rowFormFieldsWithIds: Array<FormFieldWithId> = rowFields.map(formField => ({
    id: slugify(formField.crmFieldId!),
    ...formField,
  }));

  const formFieldsWithIds: Array<FormFieldWithId> = fields.map(formField => ({
    id: slugify(
      formField.fieldType === 'singleCheckbox'
        ? getPortableTextAsString(formField._rawText)
        : formField.title,
    ),
    ...formField,
  }));

  const combinedFormFieldsWithIds = [...formFieldsWithIds, ...rowFormFieldsWithIds];

  if (combinedFormFieldsWithIds!.length !== uniqBy(combinedFormFieldsWithIds!, 'id').length) {
    throw new Error(
      'Got duplicate ids in formFieldsWithIds: ' +
        JSON.stringify(combinedFormFieldsWithIds!.map(formField => formField.id)),
    );
  }

  const [destinationUrl, setDestinationUrl] = useState(destinationUrlOnSubmit);

  useEffect(() => {
    function getDestinationUrl(): string {
      const urlParams = new URLSearchParams(window.location.search);

      const lowerCaseParams = new URLSearchParams();
      for (const [name, value] of urlParams) {
        lowerCaseParams.append(name.toLowerCase(), value.toLowerCase());
      }

      if (conditionalDestinationsUrl) {
        for (const {
          parameterName,
          parameterValue,
          destinationUrl,
        } of conditionalDestinationsUrl) {
          if (lowerCaseParams.get(parameterName.toLowerCase()) === parameterValue.toLowerCase()) {
            return destinationUrl;
          }
        }
      }

      return destinationUrlOnSubmit;
    }

    setDestinationUrl(getDestinationUrl());
  }, []);

  return (
    <ModuleLayout
      className={clsx(styles.container, styles.withForm)}
      contentClassName={clsx(styles.contentContainer)}
      hideDivider
    >
      <div className={clsx(styles.contentWrapper, styles.textRight)}>
        <div className={styles.imageContainer}>
          <Image image={image} />
        </div>
        <div className={styles.textContainer}>
          {/* <LazyHydrate ssrOnly>
            <span className={styles.countrySpan}>
              <span
                dangerouslySetInnerHTML={{
                  __html: 'Now Available Everywhere<!--{{NETLIFY_EDGE_FUNC_COUNTRY}}-->',
                }}
              ></span>
              <img src={withPrefix('/flags/{{NETLIFY_EDGE_FUNC_COUNTRY_CODE}}.svg')} alt="" />
            </span>
          </LazyHydrate> */}
          <div id="heroText" className={styles.titleContainer}>
            <h1 className={styles.title}>{replaceNewLinesWithBr(title)}</h1>
            <span className={styles.titleCopy}>{replaceNewLinesWithBr(title)}</span>
            <span className={styles.titleCopy}>{replaceNewLinesWithBr(title)}</span>
          </div>
          {text && (
            <p id="form" className={styles.text}>
              {replaceNewLinesWithBr(text)}
            </p>
          )}
          <Form
            rowFields={rowFormFieldsWithIds}
            fields={formFieldsWithIds}
            className={styles.form}
            submitButtonText={buttonText}
            destinationUrlOnSubmit={destinationUrl}
            onSubmitKeapTagIds={onSubmitKeapTagIds}
            hideLabels
          />
          <TermsAndConditionsModal
            open={isModalOpen}
            onClose={() => {
              setIsModalOpen(false);
            }}
          />
        </div>
      </div>
    </ModuleLayout>
  );
};

export default Hero;
